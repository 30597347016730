export default class StickyHeader {
  constructor(el, props, refs) {
    this.el = el;

    this.bindEvents();

    // Set scroll padding to the height of this element
    const scrollPadding = this.el.getBoundingClientRect().height;
    document.documentElement.style.scrollPaddingBlockStart = `100px`;
  }

  bindEvents() {
    const observer = new IntersectionObserver(
      ([e]) => {
        e.target.classList.toggle("is-pinned", e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );

    observer.observe(this.el);
  }
}

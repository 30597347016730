import LazyLoad from "./LazyLoad";
import ExpandContent from "./ExpandContent";
import SlideBlocks from "./SlideBlocks";
import ToggleTab from "./ToggleTab";
import LoadTabContent from "./LoadTabContent";
import DismissNotification from "./DismissNotification";
import HtmlEmbed from "./HtmlEmbed";
import FacebookVideoSdk from "./FacebookVideoSdk";
import ClickToHide from "./ClickToHide";
import DismissNotificationModal from "./DismissNotificationModal";
import ReportAnAnimal from "./ReportAnAnimal";
import Accordion from "./Accordion";
import SetTrackingUrl from "./SetTrackingUrl";
import StickyHeader from "./StickyHeader";
import ActiveAnchor from "./ActiveAnchor";

export default {
  LazyLoad,
  ExpandContent,
  SlideBlocks,
  ToggleTab,
  LoadTabContent,
  DismissNotification,
  HtmlEmbed,
  FacebookVideoSdk,
  ClickToHide,
  DismissNotificationModal,
  ReportAnAnimal,
  Accordion,
  SetTrackingUrl,
  StickyHeader,
  ActiveAnchor,
};

import Sb from "../abstract/StatefulBehavior";

const headerHeight = 100;

export default class ActiveAnchor extends Sb {
  constructor(el, props, refs) {
    super();

    // scroller
    this.el = el;
    // nav, navLink, section
    this.refs = refs;

    this.state = {
      activeLinkId: undefined,
      activeSectionId: undefined,
      prevYPosition: 0,
      direction: "down",
    };

    this.bindEvents();
  }

  get sections() {
    return this.refs.section;
  }

  get links() {
    return this.refs.navLink;
  }

  get scrollRoot() {
    return document.documentElement;
  }

  bindEvents = () => {
    const options = {
      rootMargin: `${this.refs.nav.offsetHeight * -1}px`,
      threshold: [0, 0.25],
    };

    const observer = new IntersectionObserver(this.onIntersect, options);

    this.sections.forEach((section) => {
      observer.observe(section);
    });
  };

  update = () => {
    const { activeLinkId, activeSectionId } = this.state;

    this.sections.forEach((section) => {
      section.dataset.active = section.dataset.id === activeSectionId;
    });

    this.links.forEach((link) => {
      link.dataset.active =
        link.getAttribute("href").replace("#", "") === activeLinkId;
    });
  };

  // The visible section is the first section below the top of the viewport.
  // If mobile and scrolling up, the active section is the one before.
  getActiveSection = (entry, direction) => {
    let index = this.sections.findIndex((section) => {
      return section.getBoundingClientRect().top >= 0;
    });

    const width = this.scrollRoot.clientWidth;
    index = width < 768 && direction === "up" ? index - 1 : index;

    if (index < 0) {
      return entry.target;
    } else {
      return this.sections[index];
    }
  };

  onIntersect = (entries, observer) => {
    const { activeSectionId } = this.state;

    entries.forEach((entry) => {
      let direction;

      if (this.scrollRoot.scrollTop > this.state.prevYPosition) {
        direction = "down";
      } else {
        direction = "up";
      }

      const prevYPosition = this.scrollRoot.scrollTop;

      const target = this.getActiveSection(entry, direction);

      if (target.dataset.id !== activeSectionId) {
        this.setState({
          activeLinkId: target.dataset.decade,
          activeSectionId: target.dataset.id,
          prevYPosition,
          direction,
        });
      }
    });
  };
}
